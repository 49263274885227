<template>
  <div>
    <loginForm type="forgetPassword" />
  </div>
</template>

<script>
import loginForm from '@/components/loginForm'

export default {
  name: 'ForgetPassword',
  components: {
    loginForm
  }
}
</script>
